import React, { Component } from 'react';
import { Route, Switch, BrowserRouter as Router, withRouter  } from 'react-router-dom';
import ReactGA from 'react-ga';


// Import css

import './css/bootstrap.min.css';
import './css/style.css';
ReactGA.initialize('UA-250709130-1');
ReactGA.pageview(window.location.pathname + window.location.search);


const Index1 = React.lazy(() => import('./pages/index-1'));



class App extends Component {
 
  render() {

    return (
      <React.Fragment>

        <Router>
        <React.Suspense fallback={<div>&nbsp;</div>}>
          <Switch>
              <Route path="/index1" component={Index1} />   
            

              <Route path="/" component={Index1} /> 

           </Switch>
           </React.Suspense>
        </Router>        
      </React.Fragment>
    );
  }
}



export default withRouter(App);


